<template>
  <v-sheet class="customer" id="adjustment">
    <v-row style="margin: 0% !important">
      <!-- style="height: calc(100vh - 160px) !important; overflow-y: auto !important" -->
      <v-col md="12" class="pa-0">
        <v-row style="margin: 0% !important">
          <v-col md="12" :class="[{ 'border-light-grey': false }, 'pa-0']">
            <div>
              <!-- <div class="mb-3">
                <label for="" class="field-label h4 font-weight-bold"> Count Stock </label>
              </div> -->
              <div>
                <div class="d-flex align-centern justify-space-between mb-6">
                  <div style="width: 50%" class="mr-3 mt-2">
                    <div class="mb-1 d-flex align-center">
                      <label for="stock_Date" class="field-label font-weight-medium required">
                        Date
                      </label>
                    </div>
                    <div class="">
                      <DatePicker
                        hideTopMargin
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="stock_Date"
                        placeholder="Date"
                        :rules="[vrules.required(stock_data.date, 'Date')]"
                                    :customClass="{ required: !stock_data.date }"
                        v-model="stock_data.date"
                      ></DatePicker>
                    </div>
                  </div>

                  <div style="width: 50%" >
                    <div class="mb-1 d-flex align-center">
                      <label for="" class="field-label font-weight-medium required"
                        >Reference no.</label
                      >
                    </div>
                    <div class="">
                      <!-- <TextInput
                        hideTopMargin
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="product-name-my"
                        placeholder="Reference"
                        v-model="stock_data.reference_no"
                        :rules="[vrules.required(stock_data.reference_no, 'Refrence No')]"
                                    :class="{ required: !stock_data.reference_no }"
                        :counter="30"
                      ></TextInput> -->
                      <TextInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="ref-number"
                          placeholder="Ref Number"
                          :rules="[vrules.required(stock_data.reference_no, 'Refrence No')]"
                                    :class="{ required: !stock_data.reference_no }"
                          v-model="stock_data.reference_no"
                        >
                        </TextInput>
                    </div>
                  </div>
                </div>

                <div class="mt-3 d-flex align-centern justify-space-between">
                  <div style="width: 100%" class="mr-3">
                    <div class="mb-1 d-flex align-center">
                      <label for="stock_category" class="field-label font-weight-medium">
                        Department Category
                      </label>
                    </div>
                    <div class="">
                      <AutoCompleteInput
                        hide-details
                        hideTopMargin
                        :items.sync="departments"
                        :disabled="pageLoading"
                        item-text="text"
                        item-value="id"
                        :loading="pageLoading"
                        multiple
                       
                        v-model="stock_data.department_category"
                        :rules="[vrules.required(stock_data.department_category, 'Product Name')]"
                                    :class="{ required: !stock_data.department_category }"
                        
                        id="stock_category"
                        placeholder="Department Category"
                      ></AutoCompleteInput>
                    </div>
                  </div>

                  <!-- <div style="width: 50%" class="ml-3">
                    <div class="mb-1 d-flex align-center">
                      <span class="font-weight-medium">Type</span>
                    </div>
                    <div class="">
                      <v-radio-group row class="mt-0 pt-0" hide-details v-model="stock_data.type">
                        <template v-for="(_radio, idx) in type">
                          <v-radio
                            :id="_radio.value"
                            :key="'$_' + _radio.label + '_' + _radio.value + '_' + idx"
                            color="#0d47a1"
                            :style="`border: 1px solid ${
                              stock_data.type === _radio.value ? '#0d47a1' : '#dedcdc'
                            } !important; width: 110px !important`"
                            :ripple="false"
                             :rules="[vrules.required(stock_data.type, 'Product Name')]"
                            :class="[
                              'px-4',
                              'py-1',
                              'rounded-xl',
                              { 'blue lighten-5': stock_data.type === _radio.value },{ required: !stock_data.type },
                            ]"
                            :value="_radio.value"
                          >
                            <template v-slot:label>
                              <label
                                :for="_radio.value"
                                style="font-size: 13px"
                                class="font-weight-medium"
                              >
                                {{ _radio.label }}
                              </label>
                            </template>
                          </v-radio>
                        </template>
                      </v-radio-group>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import DatePicker from "@/view/components/DatePicker";
import { QUERY } from "@/core/services/store/request.module";

import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

export default {
  name: "Product-create",
  title: "Create Product",
   props: {
    parentdetails: {
      type: [Object,Array],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      departments: [],
      stock_data: {
        date: null,
        reference_no: null,
        type: "full",
        file: null,
        file_to_download: null,
        department_category: null,
        remarks: null,
      },
      department_category: [
        {
          text: "Kitchen",
          value: "kitchen",
        },
        {
          text: "Bar Alcoholic",
          value: "bar_alcoholic",
        },
        {
          text: "Non Bar Alcoholic",
          value: "non_bar_alcoholic",
        },
      ],
      type: [
        { label: "Full", value: "full" },
        // { label: "Partial", value: "partial" },
      ],
    };
  },
  components: {
    DatePicker,
    TextInput,
    AutoCompleteInput,
  },
  computed: {},
  watch: {
    stock_data: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("form", param);
      },
    },
  },
  methods: {
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "get-departments",
        })
        .then((data) => {
          this.departments = data.departments;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },

    toDetailPage(id) {
      console.log({ id });
      this.$router.push({
        name: "Finalize Count",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
  },
  mounted() {
    
    this.getData();
  },
};
</script>
